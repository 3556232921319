import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledContainer = styled(motion.div)`
	height: 100vh;
	width: 100vw;
	background-color: #ef2626;
	/* background-color: transparent; */
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
`

export const StyledContentWrapper = styled(motion.div)`
	margin: 0 auto;
	max-width: 1166px;
	height: 100%;
`

export const StyledLogoWrapper = styled(motion.div)`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px;

	@media (max-width: 480px) {
		padding: 18px 30px;
	}
	@media (max-width: 390px) {
		padding: 18px 14px;
	}
	@media (min-width: 768px) {
		padding: 60px;
	}

	@media (min-width: 1320px) {
		padding: 38px 0;
	}
	svg {
		height: 24px;
		width: auto;
		path {
			/* fill: #fff; */
		}
		@media (min-width: 768px) {
			height: 44px;
		}
		@media (min-width: 1320px) {
			width: 128px;
			height: auto;
		}
	}
`
