import styled from 'styled-components'
import Logo from '../../../assets/Logo.svg'
import { Link } from 'gatsby'

export const StyledFooter = styled.footer`
	border-top: 1px solid #fff1f1;
	display: flex;
	justify-content: space-between;
	padding: 18px 30px;
	@media (max-width: 420px) {
		padding: 18px 30px;
	}

	@media (max-width: 390px) {
		padding: 18px 14px;
	}

	@media (min-width: 768px) {
		padding: 40px 60px;
	}
`
export const StyledLogo = styled.div<{ secondary: boolean }>`
	font-size: 14px;
	font-weight: 500;
	line-height: 160%;
	${({ secondary }) => secondary && 'color: #FFF'};
	span {
		color: ${({ secondary }) => (secondary ? '#FFF' : '#ef2626')};
		span {
			position: relative;
			bottom: 3px;
			margin-bottom: 3px;
			font-size: 16px;
		}
	}
	@media (min-width: 390px) {
		font-size: 16px;
	}
	@media (min-width: 768px) {
		font-size: 18px;
	}
`

// @ts-ignore
export const StyledLink = styled(Link)<{ secondary: boolean; term?: boolean }>`
	font-size: 14px;
	line-height: 160%;
	font-weight: 300;
	text-decoration: none;
	${({ secondary }) => secondary && 'color: #FFF'};
	${({ term }) => (term ? `display:none;` : '')};
	@media (min-width: 390px) {
		font-size: 16px;
	}
	@media (min-width: 768px) {
		font-size: 18px;
	}
	@media (min-width: 1320px) {
		${({ term }) =>
			term
				? `display:block;
		margin-left: 69px;`
				: ''};
	}
`

export const StyledRederrLogo = styled(Logo)<{ secondary: boolean }>`
	display: none;

	${({ secondary }) =>
		secondary &&
		`
		path {
			fill: #FFF;
		}
	`};
	@media (min-width: 1320px) {
		display: block;
		width: 96px;
		height: auto;
	}
`
