import { useFormik } from 'formik'
import { navigate } from 'gatsby'
import { useMemo } from 'react'
import * as Yup from 'yup'

export const useContainer = () => {
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		email: Yup.string().email().required('E-mail is required'),
		message: Yup.string().required('Message is required'),
		services: Yup.array(),
		attachments: Yup.array()
	})
	const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
		validationSchema,
		initialValues: {
			name: '',
			email: '',
			subject: '',
			message: '',
			services: [],
			attachments: []
		},
		onSubmit: async (values, { setSubmitting }) => {
			await fetch('/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: encode({ 'form-name': 'order', ...values })
			})
			setSubmitting(false)
			navigate('/')
		}
	})

	const encode = (data: any) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
			.join('&')
	}

	const selectorData = useMemo(
		() =>
			[
				{
					id: 0,
					label: 'Web Applications',
					placeholder: 'From $1000'
				},
				{
					id: 1,
					label: 'Mobile Applications',
					placeholder: 'From $1000'
				},
				{
					id: 2,
					label: 'Penetration Testing',
					placeholder: 'From $1000'
				},
				{
					id: 3,
					label: 'Digital Transformation',
					placeholder: 'From $1000'
				},
				{
					id: 4,
					label: 'Machine Learning',
					placeholder: 'From $1000'
				},
				{
					id: 5,
					label: 'Deep Learning',
					placeholder: 'From $1000'
				},
				{
					id: 6,
					label: 'I will explain later',
					placeholder: 'Price varies'
				}
			].reverse(),
		[]
	)

	return {
		values,
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
		selectorData
	}
}
