import styled from 'styled-components'
import Chevron from '../../../assets/chevron.svg'

export const StyledInputContainer = styled.div<{ maxWidth?: number }>`
	display: flex;
	flex-direction: column-reverse;
	@media (min-width: 1100px) {
		${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : null)};
	}
`

export const StyledLabel = styled.label`
	display: flex;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	border: 0 solid #ffffff80;
	border-bottom-width: 1px;
	padding: 12px 0 8px;
	cursor: pointer;
	span:not(:last-child) {
		color: #f79292;
		flex-grow: 1;
	}
	span:last-child {
		color: #fff;
	}
	&:last-child {
		font-size: 14px;
	}
	@media (min-width: 320px) {
		font-size: 15px;
		&:last-child {
			font-size: 16px;
		}
	}
	@media (min-width: 768px) {
		font-size: 18px;
		margin: 6px 0;
		&:last-child {
			font-size: 22px;
		}
	}
`

export const StyledCheckbox = styled.div<{ checked?: boolean }>`
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: ${({ checked }) => (checked ? '#F79292' : 'transparent')};
	border: 1px solid #fff;
	margin-right: 8px;
	align-self: center;
	@media (min-width: 320px) {
		width: 12px;
		height: 12px;
	}
	@media (min-width: 768px) {
		width: 14px;
		height: 14px;
	}
`

export const StyledChevron = styled(Chevron)<{ rotate?: boolean }>`
	transform: rotate(${({ rotate }) => (rotate ? '180deg' : '0deg')});
	@media (min-width: 320px) {
		height: 16px;
		width: 16px;
	}
	@media (min-width: 768px) {
		height: 22px;
		width: 22px;
	}
`
