import React, { useMemo, useRef } from 'react'
import {
	Card,
	CardButton,
	CardTagWrapper,
	CardTitleWrapper,
	CardWrapper,
	StyledBanner,
	StyledBtnArrow,
	StyledContainer,
	StyledHeading,
	StyledHeadingWord,
	StyledInfoContainer,
	StyledInfoContainerWithImage,
	StyledLinedLabel
} from './styled'
import { Accordion } from '../../components'
import { motion, useElementScroll, useTransform, useViewportScroll } from 'framer-motion'
import { useVariant } from './variant'
import { navigate } from 'gatsby'
import RederrParticles  from '../../components/RederrParticles'


const Home = () => {
	const cards = useMemo(
		() => [
			{
				amount: '1000',
				title: 'Mobile Applications',
				desc: 'Performance-based custom-made mobile application solutions.',
				tags: ['React Native', 'Kotlin', 'Next.js'],
				link: "Let's build"
			},
			{
				amount: '1000',
				title: 'Web Applications',
				desc: 'Quality-driven website solutions, that fits your business shape consistantly.',
				tags: ['Next.js', 'Node.js', 'React.js'],
				link: "Let's build"
			},
			{
				amount: '1000',
				title: 'Penetration Testing',
				desc: 'Make sure your project is secured from unwanted access or data leaks.',
				tags: ['Planning', 'Scanning', 'Access', 'Report'],
				link: "Let's test"
			},
			{
				amount: '1000',
				title: 'Digital Transformation',
				desc: 'Start digitalization of your business and reach +30% potential customers.',
				tags: ['Empathise', 'Define', 'Ideate', 'Prototype'],
				link: "Let's transform"
			},
			{
				amount: '1000',
				title: 'Machine Learning',
				desc: 'Analyze any size of data, clustering, extract meaningful information for business.',
				tags: ['Numpy', 'Pandas', 'Scikit-learning', 'Rest API'],
				link: "Let's solve"
			},
			{
				amount: '1000',
				title: 'Deep Learning',
				desc: 'Processing any visual information, that is invisible to the human eye.',
				tags: ['PyTorch', 'OpenCV', 'Numpy', 'Rest API'],
				link: "Let's solve"
			}
		],
		[]
	)

	const strenghts = useMemo(
		() => [
			'Responsibility anywhere and always.',
			'Challange when you can, not when you need.',
			'Respect everyone & you will be respected.',
			'Emotion-based customer satisfaction.',
			'Try to make the world a better place.'
		],
		[]
	)
	const strenghtsDescriptions = useMemo(
		() => [
			'Running a project with Xfive gives you full visibility into the process, and accurate time and cost reports at each incremental stage of development. Our focus is on clear communication to avoid',
			'Running a project with Xfive gives you full visibility into the process, and accurate time and cost reports at each incremental stage of development. Our focus is on clear communication to avoid',
			'Running a project with Xfive gives you full visibility into the process, and accurate time and cost reports at each incremental stage of development. Our focus is on clear communication to avoid',
			'Running a project with Xfive gives you full visibility into the process, and accurate time and cost reports at each incremental stage of development. Our focus is on clear communication to avoid',
			'Running a project with Xfive gives you full visibility into the process, and accurate time and cost reports at each incremental stage of development. Our focus is on clear communication to avoid'
		],
		[]
	)

	const headingWords = useMemo(
		() => [
			'International\u00A0',
			'Software\u00A0',
			'Engineering\u00A0',
			'Company',
			'.'
		],[]
	)

	const linedHeading = useMemo(
		() => 
			'Serving around the world, seeking challanges to solve.'
		,[]
	)

	const {
		hrVariants,
		linedLabelCharacterVariants,
		headingVariants,
		headingTextVariants,
		bannerVariants,
		bannerElementVariants,
		bannerElementButtonVariants
	} = useVariant()

	// const { scrollYProgress } = useViewportScroll()

	// const yPosAnim = useTransform(scrollYProgress, [0, 0.05], [0, -130])


	return (
		<StyledContainer>
			<StyledLinedLabel>
				<motion.hr variants={hrVariants} initial='initial' animate='show' exit='exit' />
				<span>
					{linedHeading.split('').map((item, i) => (
						<motion.span custom={i} variants={linedLabelCharacterVariants} initial='initial' animate='show'>
							{item}
						</motion.span>
					))}
				</span>
			</StyledLinedLabel>
			<StyledHeading variants={headingVariants} initial='initial' animate='show' exit='exit'>
				{headingWords.map((item, i) => (
					<StyledHeadingWord>
					<motion.span
					custom={i}
					whileHover='onHover'
					variants={headingTextVariants} 
					initial='hidden' 
					animate='visible'>{item}
					</motion.span></StyledHeadingWord>
				)
				)}
			</StyledHeading>
			<StyledBanner variants={bannerVariants} initial='initial' animate='animate'>
				<motion.h3 variants={bannerElementVariants}>Challange your ideas</motion.h3>
				<motion.p variants={bannerElementVariants}>
					We strongly believe that every idea, is a possible life-changing solution for
					mankind’s future.
				</motion.p>
				<motion.button
					onClick={() => navigate('/order')}
					variants={bannerElementButtonVariants}
					initial='initial'
					animate='animate'
					whileHover={{ scale: 1.01 }}
				>
					Challange today
				</motion.button>
			</StyledBanner>
			<StyledInfoContainerWithImage>
				<div>
					<h5>About company</h5>
					<h4>Long story-short</h4>
					<p>
						<span>Rederr</span> is an software company, specialized in web, mobile
						development with capability of rapid growth estimate of 20-30 senior, 10-15
						junior
					</p>
				</div>
			<RederrParticles/>
			</StyledInfoContainerWithImage>
			<CardWrapper>
				{cards.map((card, i) => (
					<Card key={card.title} alt={i % 2 === 1} gridAlt={[1, 2, 5].includes(i)}>
						<CardTitleWrapper>
							<div>
								<p>Starts from ${card.amount}</p>
								<h3>{card.title}</h3>
							</div>
							<p>{card.desc}</p>
						</CardTitleWrapper>
						<CardTagWrapper>
							{card.tags.map(tag => (
								<div key={tag}>{tag}</div>
							))}
						</CardTagWrapper>
						<CardButton to='/order'>
							<span>{card.link}</span>{' '}
							<StyledBtnArrow alt={i % 2 === 1} gridAlt={[1, 2, 5].includes(i)} />
						</CardButton>
					</Card>
				))}
			</CardWrapper>
			<StyledInfoContainer>
				<h5>Little about our strenghts</h5>
				<h4>Valuation of our company</h4>
			</StyledInfoContainer>
			<div
				style={{ display: 'flex', flexDirection: 'column', gap: 30, marginBottom: '60px' }}
			>
				{strenghts.map((strenght, i) => (
					<Accordion
						key={strenght}
						title={`${strenght}`}
						desc={strenghtsDescriptions[i]}
					/>
					// ${i + 1}.
				))}
			</div>
		</StyledContainer>
	)
}

export default Home
