import React, { FC, useMemo, useState } from 'react'
import { StyledDescription, StyledMinus, StyledPlus, StyledTitle, StyledWrapper } from './styled'

interface Props {
	title: string
	desc: string
}

const Accordion: FC<Props> = ({ title, desc }) => {
	// const key = useMemo(() => title.split('. '), [title])
	const [isOpen, setIsOpen] = useState(false)
	return (
		<StyledWrapper onClick={() => setIsOpen(prev => !prev)}>
			<StyledTitle open={isOpen}>
				{/* <span>{key[0]}&nbsp;</span> */}
				<p>{title}</p>
				<span>{isOpen 
				? <StyledMinus/>
				: <StyledPlus/>
				}</span>
			</StyledTitle>
			<StyledDescription open={isOpen}>{desc}</StyledDescription>
		</StyledWrapper>
	)
}

export default Accordion
