import styled from 'styled-components'
import bannerMobile from '../../images/background-home-mobile.png'
import bannerTablet from '../../images/background-home-tablet.png'
import bannerXs from '../../images/background-home-xs.png'
import banner from '../../images/background-home.png'
import ArrowLogo from '../../../assets/arrow-right.svg'
import { Link } from 'gatsby'

export const StyledContainer = styled.main`
	display: flex;
	flex-direction: column;
	padding: 30px;
	@media (max-width: 480px) {
		padding: 30px;
	}
	@media (max-width: 390px) {
		padding: 14px;
	}
	@media (max-width: 320px) {
		padding: 14px;
	}
	@media (min-width: 768px) {
		padding: 60px;
	}
	@media (min-width: 1320px) {
		padding: 120px 0 200px;
	}
`
export const StyledLinedLabel = styled.div`
	display: flex;
	color: #ef2626;
	font-weight: 500;
	font-size: 16px;
	line-height: 160%;
	align-items: center;

	hr {
		display: none;
	}
	@media (max-width: 480px) {
		font-size: 20px;
	}
	@media (max-width: 390px) {
		font-size: 18px;
	}
	@media (max-width: 320px) {
		font-size: 16px;
	}

	@media (min-width: 480px) {
		font-size: 22px;
	}
	@media (min-width: 768px) {
		font-size: 18px;
		hr {
			display: inline-block;
			width: 75px;
			height: 0;
			border-color: #ef2626;
			margin: 0;
			margin-right: 14px;
			border-width: 0;
			border-bottom-width: 2px;
		}
	}
	@media (min-width: 1320px) {
		font-size: 20px;
	}
`

export const StyledHeading = styled.h2`
	margin-top: 9px;
	font-weight: 700;
	font-size: 44px;
	span {
		color: #ef2626;
	}
	@media (max-width: 480px) {
		font-size: 53px;
	}
	@media (max-width: 420px) {
		font-size: 48px;
	}
	@media (max-width: 390px) {
		font-size: 46px;
	}
	@media (max-width: 320px) {
		font-size: 36px;
	}

	@media (min-width: 480px) {
		font-size: 62px;
	}

	@media (min-width: 768px) {
		font-size: 54px;
	}

	@media (min-width: 1320px) {
		font-size: 100px;
		margin-top: 23px;
	}
`

export const StyledBanner = styled.div`
	background-image: url(${bannerMobile});
	background-position: bottom 0 right 0;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 20px;
	margin-top: 60px;
	font-size: 14px;
	h3 {
		font-size: 18px;
		font-weight: 500;
		span {
			color: #ef2626;
		}
	}
	p {
		font-weight: 400;
		line-height: 160%;
		margin: 6px 0 8px;
		width: 85%;
	}

	button {
		cursor: pointer;
		background-color: #ef2626;
		border: none;
		color: #fff;
		padding: 14px;
		font-size: 14px;
		font-weight: 400;
	}
	@media (max-width: 480px) {
		background-image: url(${bannerXs});
		h3 {
			font-size: 22px;
		}
		button {
			margin-top: 4px;
			font-size: 16px;
		}
		p {
			font-size: 16px;
		}
	}

	@media (max-width: 320px) {
		background-image: url(${bannerXs});
		button {
			margin-top: 4px;
			padding: 12px;
			font-size: 15px;
		}
		p {
			font-size: 15px;
		}
	}

	@media (min-width: 480px) {
		h3 {
			font-size: 24px;
		}
		button {
			margin-top: 4px;
			font-size: 16px;
		}
		p {
			font-size: 18px;
		}
	}

	@media (min-width: 768px) {
		background-image: url(${bannerTablet});
		padding: 42px 46px;
		font-size: 18px;
		h3 {
			font-size: 32px;
			margin-top: 8px;
		}
		p {
			margin: 17px 0 44px;
		}
		button {
			font-size: 18px;
			padding: 0 24px;
			height: 54px;
		}
	}
	@media (min-width: 1320px) {
		background-image: url(${banner});
		padding: 90px 85px;
		margin-top: 200px;
		h3 {
			font-size: 40px;
			margin-top: 18px;
		}
		p {
			width: 45%;
		}
	}
`

export const StyledInfoContainer = styled.div`
	margin-top: 60px;
	h4 {
		font-size: 26px;
		font-weight: 700;
		line-height: 110%;
		margin-bottom: 16px;
	}
	h5 {
		color: #ef2626;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 2px;
		text-transform: uppercase;
	}
	p {
		font-size: 16px;
		font-weight: 300;
		line-height: 160%;

		span {
			color: #ef2626;
			font-weight: 500;
		}
		&:nth-child(4) {
			margin-top: 30px;
		}
	}
	div {
		margin-bottom: 12px;
	}

	@media (max-width: 320px) {
		margin-top: 40px;
		div {
			margin-bottom: 14px;
		}
		&:nth-child(2) {
			margin-top: 60px;
		}
	}
	@media (min-width: 768px) {
		/* margin-top: 100px; */
		h4 {
			font-size: 42px;
		}
		h5 {
			font-size: 14px;
		}
		p {
			font-size: 22px;
		}
		div {
			margin-bottom: 34px;
		}
	}
	@media (min-width: 1320px) {
		&:first-child {
			margin-bottom: 140px;
		}
		div {
			margin-bottom: 48px;
		}
	}
`

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 100px;
	margin: 100px 0;
	@media (min-width: 768px) {
		gap: 40px;
		margin-top: 100px 0;
	}
	@media (min-width: 1180px) {
		display: grid;
		gap: 0;
		grid-template-columns: 1fr 1fr 1fr;
		margin-top: 200px;
	}
`

export const CardTagWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	flex-direction: row;
	gap: 8px;
	margin-bottom: 20px;
	div {
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;
		padding: 2px 6px;
		border-width: 1px;
		border-style: solid;
		border-color: #ededed;
	}

	@media (max-width: 320px) {
		display: none;
	}

	@media (min-width: 480px) {
		div {
			font-size: 16px;
		}
	}

	@media (min-width: 768px) {
		gap: 18px;
		div {
			font-size: 16px;
			padding: 3px 10px;
		}
	}
`

export const StyledImageWrapper = styled.div`
	background-color: #fff4f4;
	height: 180px;
	position: relative;

	div.ignore {
		position: absolute;
		bottom: 0;
		max-width: 300px;
	}

	@media (min-width: 1100px) {
		div.ignore {
			bottom: 104px;
		}
		height: 272px;
	}
`

export const Card = styled.div<{ alt?: boolean; gridAlt?: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${({ alt }) => (alt ? 'color: #fff' : 'color: #141414')};
	div {
		${({ alt }) => (alt ? 'border-color: #FF7070' : null)};
	}

	button {
		margin-top: 4px;
		color: ${({ alt }) => (!alt ? '#EF2626' : '#fff')};
	}
	@media (min-width: 768px) {
		/* padding: 54px; */
		gap: 32px;
	}

	@media (min-width: 1320px) {
		${({ gridAlt }) => (gridAlt ? 'color: #fff' : 'color: #141414')};
		div {
			${({ gridAlt }) => (gridAlt ? 'border-color: #FF7070' : 'border-color: #EDEDED')};
		}

		button {
			color: ${({ gridAlt }) => (!gridAlt ? '#EF2626' : '#fff')};
		}
	}
`

export const CardTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;

	p {
		font-weight: 300;
		font-size: 16px;
		line-height: 160%;
	}

	div {
		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 1;
			margin-bottom: 4px;
		}
		h3 {
			font-weight: 700;
			font-size: 24px;
		}
	}
	h5 {
		color: #ef2626;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 6px;
		text-transform: uppercase;
	}

	@media (min-width: 480px) {
		p {
			font-size: 16px;
		}
		div {
			p {
				font-size: 16px;
			}
			h3 {
				font-size: 26px;
			}
		}
	}

	@media (min-width: 768px) {
		p {
			font-size: 18px;
			max-width: 93%;
		}
		div {
			p {
				font-size: 16px;
			}
			h3 {
				font-size: 32px;
			}
		}
	}
`

export const CardButton = styled(Link)`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 1;
	align-self: flex-start;
	background-color: transparent;
	border: none;
	@media (min-width: 768px) {
		font-size: 18px;
	}
`

export const StyledBtnArrow = styled(ArrowLogo)<{ alt?: boolean; gridAlt?: boolean }>`
	margin-left: 6px;
	height: 7.33px;
	width: auto;
	path {
		stroke: ${({ alt }) => (!alt ? '#EF2626' : '#fff')};
	}
	@media (min-width: 768px) {
		height: 14px;
		margin-left: 8px;
	}
	@media (min-width: 1320px) {
		path {
			stroke: ${({ gridAlt }) => (!gridAlt ? '#EF2626' : '#fff')};
		}
	}
`

export const StyledInfoContainerWithImage = styled(StyledInfoContainer)`
	div:nth-child(2) {
		display: none;
	}
	@media (max-width: 320px) {
		p {
			font-size: 15px;
		}
	}

	@media (min-width: 1320px) {
		div:nth-child(2) {
			display: block;
		}
		display: grid;
		grid-template-columns: 1fr 419px;
		gap: 100px;
		align-items: center;
	}
`
