import { Variants } from 'framer-motion'
import { useMemo } from 'react'

export const useVariant = () => {
	const headingVariants: Variants = useMemo(
		() => ({
			initial: {
			},
			show: {
			}
		}),
		[]
	)

	const headingTextVariants = useMemo(
		() => ({
			hidden: {
				y: "200%",
			  },
			visible: (i: number) => ({
				y: 0,
				transition: { duration: 1, delay: i * 0.15 }
			  }),
			onHover: {
				x: 2,
				color: '#ef2626',
				transition: {
					type: 'tweet'
				}
			}

		}),
		[]
	)


	const hrVariants = useMemo(
		() => ({
			initial: {
				width: 0,
			},
			show: {
				width: 75,
				transition: {
					duration: 0.2,
					type: 'tweet',
					delay: 1.2,
				},
			},
			exit: {
			}
		}),
		[]
	)

	const linedLabelCharacterVariants = useMemo(
		() => ({
			initial: {
				opacity: 0
			},
			show: (i:number) => ({
				opacity: 1,
				transition: {
					duration: 0.01,
					type: 'tweet',
					delay: (i * 0.03) + 1.5,
				}
			}),
			exit: {
			}
		}),
		[]
	)

	const bannerVariants: Variants = useMemo(
		() => ({
			initial: {
				scale: 0.8,
				y: 200,
				opacity: 0
			},
			animate: {
				scale: 1,
				y: 0,
				opacity: 1,
				transition: {
					type: 'spring',
					duration: 1.2,
					mass: 0.3,
					damping: 4,
					when: 'beforeChildren',
					staggerChildren: 0.4
				}
			}
		}),
		[]
	)

	const bannerElementVariants: Variants = useMemo(
		() => ({
			initial: {
				y: 80,
				opacity: 0
			},
			animate: {
				y: 0,
				opacity: 1,
				transition: {
					type: 'spring'
					// duration: 1
				}
			}
		}),
		[]
	)

	const bannerElementButtonVariants: Variants = useMemo(
		() => ({
			initial: {
				y: 80,
				opacity: 0
			},
			animate: {
				y: 0,
				opacity: 1,
				transition: {
					type: 'spring',
					delay: 1.8
				}
			},
			whileHover: {
				scale: 1.02
			}
		}),
		[]
	)

	return {
		hrVariants,
		headingVariants,
		headingTextVariants,
		linedLabelCharacterVariants,
		bannerVariants,
		bannerElementVariants,
		bannerElementButtonVariants
	}
}
