import React, { FC, useMemo } from 'react'
import {
	Card,
	CardButton,
	CardTagWrapper,
	CardTitleWrapper,
	CardWrapper,
	StyledBtnArrow,
	StyledContainer,
	StyledImageWrapper,
	StyledInfoContainer
} from './styled'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

interface Props {
	data?: any
}

const About: FC<Props> = ({ data }) => {
	const cards = useMemo(
		() => [
			{
				title: 'Lasha Mirzelashvili',
				desc: 'Rookie entrepreneur with 5+ years of experience creating tech products.',
				tags: ['Linkedin', 'Twitter', 'Email'],
				image: 'lasha',
				position: 'Co-founder, CEO'
			},
			{
				title: 'Giorgi Adeishvili',
				desc: 'Development stack lead with 4+ years of experience creating tech products.',
				tags: ['Linkedin', 'Twitter', 'Email'],
				image: 'intima',
				position: 'Co-founder, CTO'
			},
			{
				title: 'Levan Bezhanidze',
				desc: 'ML Engineer with 3+ years of experience creating tech products.',
				tags: ['Linkedin', 'Twitter', 'Email'],
				image: 'levana',
				position: 'Co-founder, ML Engineer'
			}
		],
		[]
	)

	return (
		<StyledContainer>
			<StyledInfoContainer>
				<h5>About company</h5>
				<h4>Long story-short</h4>
				<p>
					<span>Rederr</span> is an software company, specialized in web, mobile
					development with capability of rapid growth estimate of 20-30 senior, 10-15
					junior
				</p>
				<p>
					<span>Rederr</span> is an software company, specialized in web, mobile
					development with capability of rapid growth estimate of 20-30 senior, 10-15
					junior
				</p>
			</StyledInfoContainer>
			<StyledInfoContainer>
				<h5>Our team</h5>
				<h4>Humans behind</h4>
				<p>
					<span>Rederr</span> is an software company, specialized in web, mobile
					development with capability of rapid growth estimate of 20-30 senior, 10-15
					junior
				</p>
			</StyledInfoContainer>
			{/* <CardWrapper>
				{cards.map((card, i) => {
					const edges = data.allImageSharp.edges
					const gatsbyImageData = edges.filter((edge: any) =>
						edge.node.gatsbyImageData.images.sources[0].srcSet.includes(card.image)
					)[0].node.gatsbyImageData

					return (
						<Card key={card.title}>
							<StyledImageWrapper>
								<GatsbyImage
									image={gatsbyImageData}
									alt='rederr cup'
									imgStyle={{
										height: '100%',
										marginBottom: 0
									}}
									className='ignore'
									// style={{
									// 	position: 'relative',
									// 	bottom: 104
									// }}
								/>
							</StyledImageWrapper>
							<CardTitleWrapper>
								<div>
									<h5>{card.position}</h5>
									<h3>{card.title}</h3>
								</div>
								<p>{card.desc}</p>
							</CardTitleWrapper>
							<CardTagWrapper>
								{card.tags.map(tag => (
									<div key={tag}>{tag}</div>
								))}
							</CardTagWrapper>
						</Card>
					)
				})}
			</CardWrapper> */}
		</StyledContainer>
	)
}

export default About
