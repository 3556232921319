import * as React from "react"
import ParticleImage, { ParticleOptions, forces, Vector } from "react-particle-image"
import { StyledContainer } from './styled'

const particleOptions: ParticleOptions = {
  filter: ({ x, y, image }) => {
    // Get pixel
    const pixel = image.get(x, y)

    return pixel.r === 239
  },
  color: ({ x, y, image }) => "#ef2626",
  friction: () => 0.15,
  initialPosition: ({ canvasDimensions }) => {
    return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2)
  },
}

const motionForce = (x: number, y: number) => {
   return forces.disturbance(x,y,30)
  
}

const RederrParticles = () => {
  return (
    <StyledContainer>
    <ParticleImage
    src={"r-r-r.png"}
    width={500}
    height={500}
    entropy={10}
    scale={0.65}
    maxParticles={6000}
    particleOptions={particleOptions}
    mouseMoveForce={motionForce}
    touchMoveForce={motionForce}
    backgroundColor="none"
    color="#ef2626"
    />
    </StyledContainer>
  )
}

export default RederrParticles