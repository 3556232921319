import React from 'react'
import { FileUpload, Input } from '../../components'
import { useContainer } from './container'
import {
	StyledContactInfo,
	StyledContactInfoWrapper,
	StyledContainer,
	StyledForm,
	StyledHeading,
	StyledInfo,
	StyledLink,
	StyledSubmit
} from './styled'

const Contact = () => {
	const { handleSubmit, handleChange, setFieldValue, values, errors } = useContainer()
	return (
		<StyledContainer>
			<div>
				<StyledHeading>Contact us</StyledHeading>
				<StyledForm
					onSubmit={handleSubmit}
					data-netlify='true'
					method='POST'
					name='contact'
				>
					<input type='hidden' name='form-name' value='contact' />
					<Input
						label='What can we call you?'
						placeholder='Name'
						name='name'
						value={values.name}
						error={errors.name}
						onChange={handleChange}
						required
					/>
					<Input
						label='How can we adress you?'
						placeholder='Email'
						type='email'
						name='email'
						value={values.email}
						error={errors.email}
						onChange={handleChange}
						required
					/>
					<Input
						label='What is message about?'
						placeholder='Subject'
						name='subject'
						value={values.subject}
						error={errors.subject}
						onChange={handleChange}
						required
					/>
					<Input
						label='What is in your mind?'
						placeholder='Message'
						name='message'
						type='textarea'
						value={values.message}
						error={errors.message}
						onChange={handleChange}
						required
					/>
					<FileUpload
						name='attachments'
						value={values.attachments}
						setValue={setFieldValue}
					/>
					<StyledInfo>
						*Information you provided is under <span>NDA Protection</span>
					</StyledInfo>
					<StyledSubmit type='submit'>Let your idea happend</StyledSubmit>
					<StyledLink to='/'>I will continue later</StyledLink>
				</StyledForm>
			</div>
			<StyledContactInfoWrapper>
				<StyledContactInfo>
					<p>Address</p>
					<p>12 Sulkhan Tsintsadze st.,</p>
					<p>Tbilisi, Georgia, 0179</p>
				</StyledContactInfo>
				<StyledContactInfo>
					<p>Contact</p>
					<p>support@rederr.com</p>
					<p>+995 32 2525 525</p>
				</StyledContactInfo>
				<StyledContactInfo>
					<p>Availability</p>
					<p>Monday-Thursday</p>
					<p>11:00-18:00 GMT+4</p>
				</StyledContactInfo>
			</StyledContactInfoWrapper>
		</StyledContainer>
	)
}

export default Contact
