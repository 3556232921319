import React, {
	ChangeEvent,
	ChangeEventHandler,
	FC,
	InputHTMLAttributes,
	useCallback,
	useMemo
} from 'react'
import {
	StyledCloseX,
	StyledFileUpload,
	StyledInput,
	StyledInputContainer,
	StyledLabel,
	StyledLabelWrapper,
	StyledPaperClip
} from './styles'

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
	label?: string
	error?: string
	setValue?: (field: string, value: any) => void
	value: File[]
	inputMaxWidth?: number
	containerMaxWidth?: number
}

const Input: FC<Props> = ({
	label = '',
	name = '',
	inputMaxWidth,
	containerMaxWidth,
	placeholder = '',
	error = '',
	required = false,
	setValue = () => {},
	value = [],
	...props
}) => {
	const onChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.files) {
				setValue('attachments', value.concat(Array.from(e.target.files)))
			}
		},
		[value, setValue]
	)

	const onRemove = useCallback(
		(selectedFile: File) => {
			setValue(
				'attachments',
				value.filter(file => file !== selectedFile)
			)
		},
		[value, setValue]
	)

	const renderValue = useMemo(
		() =>
			value.map(file => (
				<StyledLabelWrapper maxWidth={inputMaxWidth}>
					<StyledLabel onClick={() => onRemove(file)}>{file.name}</StyledLabel>
					<StyledCloseX />
				</StyledLabelWrapper>
			)),
		[value]
	)
	return (
		<StyledFileUpload maxWidth={containerMaxWidth}>
			{renderValue}
			<StyledInputContainer>
				<StyledInput
					type='file'
					multiple
					id={name}
					name={name}
					onChange={onChange}
					{...props}
					placeholder='dummy'
				/>
				<StyledLabelWrapper maxWidth={inputMaxWidth}>
					<StyledLabel htmlFor={name}>
						{label ? label : value.length ? 'Attach more files' : 'Attach your files'}
						&nbsp;
					</StyledLabel>
					<StyledPaperClip />
				</StyledLabelWrapper>
			</StyledInputContainer>
		</StyledFileUpload>
	)
}

export default Input
