import React from 'react'
import { FileUpload, Input, Select } from '../../components'
import { useContainer } from './container'
import {
	StyledContainer,
	StyledForm,
	StyledHeading,
	StyledInfo,
	StyledLink,
	StyledSubmit,
	StyledButtons
} from './styled'

const Order = () => {
	const { handleSubmit, handleChange, setFieldValue, values, errors, selectorData } =
		useContainer()
	return (
		<StyledContainer>
			<StyledHeading>
				Tell us about
				<br />
				your project
			</StyledHeading>
			<StyledForm onSubmit={handleSubmit} data-netlify='true' method='POST' name='order'>
				<input type='hidden' name='form-name' value='order' />
				<Input
					label='What can we call you?'
					placeholder='Name'
					name='name'
					value={values.name}
					error={errors.name}
					onChange={handleChange}
					inputMaxWidth={450}
					required
				/>
				<Input
					label='How can we adress you?'
					placeholder='Email'
					name='email'
					value={values.email}
					error={errors.email}
					onChange={handleChange}
					inputMaxWidth={550}
					required
				/>
				<Select
					// label='Which service you want us to provide?'
					label='Service we can provide'
					placeholder='Select'
					data={selectorData}
					value={values.services}
					setValue={setFieldValue}
					inputMaxWidth={650}
				/>
				<Input
					label='Let us dive into more details'
					name='message'
					value={values.message}
					error={errors.message}
					onChange={handleChange}
					inputMaxWidth={750}
					required
				/>
				<FileUpload
					name='attachements'
					value={values.attachments}
					setValue={setFieldValue}
					inputMaxWidth={240}
					containerMaxWidth={750}
				/>
				<StyledInfo>
					*Information you provided is unde <span>NDA Protection</span>
				</StyledInfo>
				<StyledButtons>
					<StyledSubmit type='submit'>Let your idea happend</StyledSubmit>
					<StyledLink to='/'>I will continue later</StyledLink>
				</StyledButtons>
			</StyledForm>
		</StyledContainer>
	)
}

export default Order
