import { useFormik } from 'formik'
import * as Yup from 'yup'
import { navigate } from 'gatsby'

export const useContainer = () => {
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		email: Yup.string().email().required('E-mail is required'),
		subject: Yup.string().required('Subject is required'),
		message: Yup.string().required('Message is required'),
		attachments: Yup.array()
	})
	const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
		validationSchema,
		initialValues: {
			name: '',
			email: '',
			subject: '',
			message: '',
			attachments: []
		},
		onSubmit: async (values, { setSubmitting }) => {
			await fetch('/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: encode({ 'form-name': 'contact', ...values })
			})
			setSubmitting(false)
			navigate('/')
		}
	})

	const encode = (data: any) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
			.join('&')
	}

	return {
		values,
		errors,
		handleChange,
		handleSubmit,
		setFieldValue
	}
}
