import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from '../../../assets/Logo.svg'
import Burger from '../../../assets/burger.svg'
import { motion } from 'framer-motion'

export const StyledHeader = styled(motion.header)`
	display: flex;
	justify-content: space-between;
	padding: 30px;
	position: sticky;
	z-index: 10;
	top: 0;
	background-color: #fff;

	@media (max-width: 480px) {
		padding: 18px 30px;
	}
	@media (max-width: 390px) {
		padding: 18px 14px;
	}
	@media (min-width: 768px) {
		padding: 60px;
	}

	@media (min-width: 1320px) {
		padding: 38px 0;
	}
	&.scrolling {
		padding-top: 10px;
		padding-bottom: 10px;
	}
`
export const StyledLogo = styled(Logo)<{ secondary: boolean }>`
	height: 24px;
	width: auto;

	${({ secondary }) =>
		secondary &&
		`
		path {
			fill: #FFF;
		}
	`};
	@media (min-width: 768px) {
		height: 44px;
	}
	@media (min-width: 1320px) {
		width: 128px;
		height: auto;
	}
`

export const StyledBurger = styled(Burger)<{ secondary: boolean }>`
	height: 22px;
	width: auto;
	cursor: pointer;

	${({ secondary }) =>
		secondary &&
		`
		path {
			fill: #FFF;
		}
	`};

	@media (min-width: 768px) {
		height: 30px;
	}
`
export const StyledLogoLink = styled(Link)`
	cursor: pointer;
`
