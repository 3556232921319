import styled from 'styled-components'

export const StyledInputContainer = styled.div<{ maxWidth?: number }>`
	display: flex;
	flex-direction: column-reverse;
	@media (min-width: 1100px) {
		${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : null)};
	}
`

export const StyledLabel = styled.label`
	display: flex;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	position: relative;
	top: 10px;
	transition: all 0.3s;
	span:not(:last-child) {
		color: #f79292;
		flex-grow: 1;
	}
	span:last-child {
		color: #fff;
	}
	@media (min-width: 320px) {
		font-size: 16px;
		top: 14px;
	}
	@media (min-width: 768px) {
		font-size: 22px;
	}
`

export const StyledInput = styled.input`
	::placeholder {
		opacity: 0;
	}
	background-color: transparent;
	border: 1px solid #ffffff80;
	border-width: 0;
	border-bottom-width: 1px;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	box-shadow: none;
	padding: 0;
	:focus {
		outline: none;
		& + label {
			color: #f79292;
			top: 0;
		}
	}
	:not(:placeholder-shown) + label {
		color: #f79292;
		top: 0;
	}
	@media (min-width: 320px) {
		font-size: 16px;
	}
	@media (min-width: 768px) {
		font-size: 22px;
	}
`
