import styled from 'styled-components'
import PaperClip from '../../../assets/paper-clip.svg'
import CloseX from '../../../assets/close-x.svg'

export const StyledFileUpload = styled.div<{ maxWidth?: number }>`
	display: flex;
	flex-direction: column;
	gap: 18px;
	text-overflow: ellipsis;
	@media (min-width: 1100px) {
		flex-direction: row;
		gap: 38px;
		flex-wrap: wrap;
		${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : null)};
	}
`

export const StyledLabelWrapper = styled.div<{ maxWidth?: number }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 0 solid #ffffff80;
	border-bottom-width: 1px;
	label {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: inline-block;
	}
	@media (min-width: 1100px) {
		${({ maxWidth }) => (maxWidth ? `width: ${maxWidth}px` : null)};
		label {
			${({ maxWidth }) => (maxWidth ? `width: ${maxWidth - 20}px` : null)};
		}
	}
`

export const StyledInputContainer = styled.div`
	display: flex;
	flex-direction: column-reverse;
`

export const StyledLabel = styled.label`
	display: flex;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	padding: 8px 0;

	justify-content: space-between;

	@media (min-width: 320px) {
		font-size: 16px;
	}
	@media (min-width: 768px) {
		font-size: 22px;
		padding: 12px 0;
	}
`

export const StyledInput = styled.input`
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
	opacity: 0;
	width: 0px;
`

export const StyledPaperClip = styled(PaperClip)`
	width: auto;
	height: 14px;
	@media (min-width: 320px) {
		height: 16px;
	}
	@media (min-width: 768px) {
		height: 22px;
	}
`

export const StyledCloseX = styled(CloseX)`
	width: auto;
	height: 12px;
`
