import { Link } from 'gatsby'
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import Footer from '../Footer'
import {
	StyledCloseX,
	StyledListWrapper,
	StyledLogo,
	StyledNav,
	StyledNavContainer,
	StyledNavHeader,
	StyledNavItems,
	StyledNavSocials
} from './styled'

const Nav = forwardRef<NavRef, {}>((_, ref) => {
	const navRef = useRef<HTMLElement>(null)

	useImperativeHandle(ref, () => ({
		show: () => {
			if (navRef.current) {
				navRef.current.style.display = 'flex'
			}
		},
		close: () => onClose()
	}))

	const onClose = useCallback(() => {
		if (navRef.current) {
			navRef.current.style.display = 'none'
		}
	}, [navRef.current])

	return (
		<StyledNav ref={navRef}>
			<StyledNavContainer>
				<StyledNavHeader>
					<Link to='/'>
						<StyledLogo onClick={() => onClose()} />
					</Link>
					<StyledCloseX onClick={onClose} />
				</StyledNavHeader>
				<StyledListWrapper>
					<StyledNavItems>
						<li>
							<Link to='/'>Main</Link>
						</li>
						<li>
							<Link to='/about'>About</Link>
						</li>
						<li>
							<Link to='/contact'>Contact</Link>
						</li>
					</StyledNavItems>
					<StyledNavSocials>
						<p>Socials</p>
						<li>
							<Link to='https://fb.com/'>Facebook</Link>
						</li>
						<li>
							<Link to='https://linkedin.com/'>Linkedin</Link>
						</li>
						<li>
							<Link to='https://twitter.com/'>Twitter</Link>
						</li>
					</StyledNavSocials>
				</StyledListWrapper>
			</StyledNavContainer>
			<Footer secondary />
		</StyledNav>
	)
})

export default Nav
