import React, { FC, useEffect } from 'react'
import Intro from '../Intro'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { StyledBurger, StyledHeader, StyledLogo, StyledLogoLink } from './styles'
import { useVariant } from './variant'

interface Props {
	onBurger?: () => void
	secondary?: boolean
	intro?: boolean
}

const Header: FC<Props> = ({ onBurger = () => {}, secondary = false, intro = false }) => {
	const { headerVariants } = useVariant()

	const { scrollY, scrollYProgress } = useViewportScroll()
	const [hidden, setHidden] = React.useState(1)

	const [initialHeader, setInitialHeader] = React.useState(1)
	const headerRef = React.useRef<HTMLElement>(null)

	const headerY = useTransform(scrollYProgress, [0, 0.2, 0.3], ['0%', '0%', '-100%'])

	useEffect(() => {
		scrollY.onChange(y => {
			if (y < scrollY.getPrevious()) {
				setHidden(1)
				if (headerRef.current) {
					console.log(y, 'ccc', headerRef.current.classList)
					if (y > 120 && !headerRef.current.classList.contains('scrolling')) {
						headerRef.current.classList.add('scrolling')
					} else if (y < 121 && headerRef.current.classList.contains('scrolling')) {
						window.scrollTo({ top: 0 })
						headerRef.current.classList.remove('scrolling')
					}
				}
			} else if (y > scrollY.getPrevious() && y > 220) {
				setHidden(0)
			}
		})
	}, [])

	return (
		<StyledHeader
			ref={headerRef}
			transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
			style={
				initialHeader === 1
					? { opacity: hidden }
					: { opacity: hidden, paddingTop: 10, paddingBottom: 10 }
			}
		>
			<StyledLogoLink to='/'>
				<StyledLogo secondary={secondary} />
			</StyledLogoLink>
			<StyledBurger onClick={onBurger} secondary={secondary} />
			{intro ? <Intro /> : null}
		</StyledHeader>
	)
}

export default Header
