import React, { FC, InputHTMLAttributes } from 'react'
import { StyledInput, StyledInputContainer, StyledLabel } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label: string
	error?: string
	inputMaxWidth?: number
}

const Input: FC<Props> = ({
	label,
	inputMaxWidth,
	name = '',
	placeholder = '',
	error = '',
	required = false,
	...props
}) => {
	return (
		<StyledInputContainer maxWidth={inputMaxWidth}>
			<StyledInput id={name} name={name} {...props} placeholder='dummy' />
			<StyledLabel htmlFor={name}>
				{label}&nbsp;
				<span>{placeholder}</span>&nbsp;
				<span>{required ? '*' : null}</span>
			</StyledLabel>
		</StyledInputContainer>
	)
}

export default Input
