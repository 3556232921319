import styled from 'styled-components'
import AccordionPlus from '../../../assets/accordion-plus.svg'
import AccordionMinus from '../../../assets/accordion-minus.svg'

export const StyledWrapper = styled.div`
	border: 1px solid #fff1f1;
	padding: 20px;
	cursor: pointer;
	@media (min-width: 768px) {
		padding: 40px;
	}
`

export const StyledTitle = styled.div<{ open: boolean }>`
	display: flex;
	font-size: 16px;
	${({ open }) => (open ? `color: #ef2626;` : '')};
	align-items: center;
	p {
		flex: 1;
		font-weight: 500;
		line-height: 160%;
	}
	span {
		color: #ef2626;
		font-weight: 500;
	}

	@media (min-width: 390px) {
		font-size: 18px;
	}
	@media (min-width: 768px) {
		font-size: 24px;
	}
`

export const StyledPlus = styled(AccordionPlus)`
	width: 18px;
	height: 18px;
	@media (min-width: 768px) {
		width: 24px;
		height: 24px;
	}
`
export const StyledMinus = styled(AccordionMinus)`
	width: 18px;
	height: 18px;
	@media (min-width: 768px) {
		width: 24px;
		height: 24px;
	}
`


export const StyledDescription = styled.div<{ open: boolean }>`
	font-size: 14px;
	font-weight: 300;
	margin-top: 16px;
	line-height: 160%;
	max-width: 80%;
	display: ${({ open }) => (open ? `block` : 'none')};
	@media (min-width: 768px) {
		font-size: 18px;
	}
`
