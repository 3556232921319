import React, { FC, useCallback, useRef } from 'react'
import { createGlobalStyle } from 'styled-components'
import Footer from '../Footer'
import Header from '../Header'
import Nav from '../Nav'

interface Props {
	secondary?: boolean
	intro?: boolean
}

const GlobalStyle = createGlobalStyle`
    *, *::after, *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: inherit;
        line-height: inherit;
        font-family: 'Poppins';
		text-decoration: none;
		list-style: none;
    }
    html {
        color: #141414;
        line-height: 1;

		::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;
    }
`

const Layout: FC<Props> = ({ children, secondary = false, intro = false }) => {
	const navRef = useRef<NavRef>(null)

	const onBurger = useCallback(() => {
		if (navRef.current) {
			navRef.current.show()
		}
	}, [navRef.current])
	return (
		<>
			<GlobalStyle />
			<div
				style={{
					background: secondary ? '#EF2626' : '#fff'
				}}
			>
				<div
					style={{
						maxWidth: 1166,
						margin: '0 auto',
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100vh'
					}}
				>
					<Header secondary={secondary} onBurger={onBurger} intro={intro} />
					<Nav ref={navRef} />
					{children}
				</div>
				<Footer secondary={secondary} />
			</div>
		</>
	)
}

export default Layout
