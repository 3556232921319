import { Link } from 'gatsby'
import styled from 'styled-components'

export const StyledContainer = styled.main`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 30px;
	@media (max-width: 480px) {
		padding: 30px;
	}
	@media (max-width: 390px) {
		padding: 14px;
	}
	@media (min-width: 768px) {
		padding: 60px;
	}
	@media (min-width: 1320px) {
		padding: 120px 0;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
`

export const StyledHeading = styled.h2`
	font-weight: 700;
	font-size: 26px;
	line-height: 100%;
	color: #ffffff;
	margin-bottom: 34px;
	@media (min-width: 768px) {
		font-size: 60px;
	}
`

export const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 18px;
`

export const StyledInfo = styled.p`
	margin: 16px 0 10px;
	color: #fff;
	font-size: 12px;
	line-height: 120%;
	font-weight: 300;
	font-style: italic;
	span {
		color: #ffffff80;
		font-weight: 400;
	}
	@media (min-width: 320px) {
		font-size: 14px;
		line-height: 120%;
	}
	@media (min-width: 768px) {
		font-size: 16px;
		margin-right: 100px;
	}
`

export const StyledSubmit = styled.button`
	color: #fff;
	height: 48px;
	background: transparent;
	border: 1px solid #fff;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	@media (min-width: 320px) {
		font-size: 16px;
		height: 60px;
	}
	@media (min-width: 768px) {
		font-size: 22px;
		height: 68px;
	}
`

export const StyledLink = styled(Link)`
	align-self: center;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	margin-top: 8px;
	text-decoration: underline;
	cursor: pointer;
	@media (min-width: 320px) {
		font-size: 16px;
		margin-top: 13px;
	}
	@media (min-width: 768px) {
		font-size: 22px;
		margin-top: 40px;
		margin-bottom: 100px;
	}
`

export const StyledContactInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 22px;
	margin: 48px 0;
	@media (min-width: 768px) {
		gap: 44px;
		margin: 0;
	}

`

export const StyledContactInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
	p:first-child {
		font-size: 12px;
		line-height: 100%;
		color: #f79292;
	}
	@media (min-width: 320px) {
		font-size: 16px;
		p:first-child {
			font-size: 14px;
		}
	}
	@media (min-width: 768px) {
		font-size: 24px;
		p:first-child {
			font-size: 20px;
		}
	}
`
