import styled from 'styled-components'
import Logo from '../../../assets/Logo.svg'
import CloseX from '../../../assets/close-x.svg'
import { Link } from 'gatsby'

export const StyledNav = styled.nav`
	height: 100vh;
	width: 100vw;
	background-color: #ef2626;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 100;
	display: none;
	flex-direction: column;
`

export const StyledNavContainer = styled.div`
	max-width: 1166px;
	margin: 0 auto;
	/* padding: 30px; */
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: end;
	flex: 1;
`

export const StyledNavHeader = styled.div`
	align-self: stretch;
	display: flex;
	justify-content: space-between;
	padding: 30px;

	@media (max-width: 480px) {
		padding: 18px 30px;
	}
	@media (max-width: 390px) {
		padding: 18px 14px;
	}
	@media (min-width: 768px) {
		padding: 60px;
	}

	@media (min-width: 1320px) {
		padding: 38px 0;
	}
`

export const StyledLogo = styled(Logo)`
	path {
		fill: #fff;
	}
	height: 24px;
	width: auto;

	@media (min-width: 768px) {
		height: 44px;
	}
	@media (min-width: 1320px) {
		width: 128px;
		height: auto;
	}
`

export const StyledCloseX = styled(CloseX)`
	cursor: pointer;
	path {
		fill: #fff;
	}
	height: 16px;
	width: auto;

	@media (min-width: 768px) {
		height: 26px;
	}
`

export const StyledListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 48px;
	margin-top: 48px;
	flex: 0 1 100%;
	padding: 0 30px;

	@media (max-width: 480px) {
		padding: 0 30px;
	}
	@media (max-width: 390px) {
		padding: 0 14px;
	}
	@media (min-width: 768px) {
		padding: 0 60px;
	}

	@media (min-width: 1320px) {
		padding: 0;
	}
`

export const StyledNavItems = styled.ul`
	li {
		font-family: 500;
		font-size: 36px;
		color: #fff;
		:not(:last-child) {
			margin-bottom: 32px;
		}
		@media (min-width: 768px) {
			font-size: 70px;
		}
	}
`

export const StyledNavSocials = styled.ul`
	font-family: 500;
	font-size: 26px;
	color: #fff;
	p {
		margin-bottom: 22px;
		color: #f79292;
		font-size: 18px;
		@media (min-width: 768px) {
			font-size: 20px;
		}
	}
	li {
		:not(:last-child) {
			margin-bottom: 22px;
		}
	}
`

export const StyledNavFooter = styled.div`
	flex: 1;
	align-self: stretch;
	display: flex;
	justify-content: space-between;
`

// @ts-ignore
export const StyledLink = styled(Link)`
	color: #fff;
	font-size: 12px;
	line-height: 160%;
	text-decoration: none;
	@media (min-width: 768px) {
		font-size: 18px;
	}
`

export const StyledFooterLogo = styled.div`
	font-size: 12px;
	font-weight: 500;
	line-height: 160%;
	color: #fff;
	span {
		span {
			position: relative;
			bottom: 3px;
			margin-bottom: 3px;
			font-size: 16px;
		}
	}
	@media (min-width: 768px) {
		font-size: 18px;
	}
`
