import React, { FC } from 'react'
import { StyledFooter, StyledLink, StyledLogo, StyledRederrLogo } from './styles'

interface Props {
	secondary?: boolean
}

const Footer: FC<Props> = ({ secondary = false }) => {
	return (
		<StyledFooter
			style={
				secondary
					? {
							borderColor: '#fff1f114'
					  }
					: {}
			}
		>
			<div
				style={{
					flex: 1,
					maxWidth: 1166,
					margin: '0 auto',
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<StyledLogo secondary={secondary}>
					© 2022{' '}
					<span>
						Redder <span>®</span>
					</span>
				</StyledLogo>
				<div style={{ display: 'flex' }}>
					<StyledLink secondary={secondary} to='/policy'>
						Privacy Policy
					</StyledLink>
					<StyledLink secondary={secondary} to='/terms-and-conditions' term>
						Terms & Conditions
					</StyledLink>
				</div>
				<StyledRederrLogo secondary={secondary} />
			</div>
		</StyledFooter>
	)
}

export default Footer
