import React, { FC, useCallback, useMemo, useState } from 'react'
import { StyledCheckbox, StyledChevron, StyledInputContainer, StyledLabel } from './styles'

interface Props {
	label: string
	placeholder?: string
	data?: { label: string; id: number; placeholder?: string }[]
	value?: { label: string; id: number; placeholder?: string }[]
	setValue?: (field: string, value: any) => void
	inputMaxWidth?: number
}

const Select: FC<Props> = ({
	label,
	inputMaxWidth = 0,
	value = [],
	setValue = () => {},
	placeholder = '',
	data = []
}) => {
	const [open, setOpen] = useState(false)

	const onSelect = useCallback(
		(item: { label: string; id: number; placeholder?: string }) => {
			if (!value.filter(val => val === item)[0]) {
				value.push(item)
				setValue('services', value)
			} else {
				setValue(
					'services',
					value.filter(val => val !== item)
				)
			}
		},
		[value, setValue]
	)
	const renderData = () => {
		if (open) {
			return data.map(item => (
				<StyledLabel onClick={() => onSelect(item)}>
					<StyledCheckbox checked={!!value.filter(val => val === item)[0]} />
					{item.label}&nbsp;
					<span>{item.placeholder}</span>&nbsp;
					<span />
				</StyledLabel>
			))
		} else {
			return data.map(item =>
				!!value.filter(val => val === item)[0] ? (
					<StyledLabel onClick={() => onSelect(item)}>
						<StyledCheckbox checked={!!value.filter(val => val === item)[0]} />
						{item.label}&nbsp;
						<span>{item.placeholder}</span>&nbsp;
						<span />
					</StyledLabel>
				) : null
			)
		}
	}

	return (
		<StyledInputContainer maxWidth={inputMaxWidth}>
			{renderData()}
			<StyledLabel onClick={() => setOpen(prev => !prev)}>
				{label}&nbsp;
				<span>{placeholder}</span>&nbsp;
				<StyledChevron rotate={open} />
			</StyledLabel>
		</StyledInputContainer>
	)
}

export default Select
