import { Variants } from 'framer-motion'
import { useMemo } from 'react'


export const useVariant = () => {
    const headerVariants: Variants = useMemo(
        () => ({
            hidden: {
			  },
			visible: (i: number) => i ? {
                paddingTop: 10,
                paddingBottom: 10
            } : {
                
            }
        }),
        []
    )

    return {
        headerVariants
    }
}